angular.module('mainApp').factory('previewAdapters', function(WebGLAdapter, FallbackAdapter) {

    var views = require('data').config.viewList

    return {
        'adapters': {
            'webgl': new WebGLAdapter({
                'collections': views,
            }),
            'fallback': new FallbackAdapter({
                'collections': views,
            }),
        },
    }
})
