'use strict'

angular.module('mainApp').factory('updateCutAndFitTypes', function (_, designData) {

    function cleanKey(key){
        return key.replace(/^cutType_/,'').replace(/^fitType_/,'')
    }

    function getCutTypeMapper(cutTypeMap){
        return function(cutTypeDef, cutTypeId){
            var skuData = cutTypeMap && ['cutType_'+cutTypeId]
            var abbr = (skuData && skuData.price) ? ('$'+skuData.price): undefined
            return _.defaults({abbr: abbr}, cutTypeDef)
        }
    }

    function updateFitAndCutTypes(template) {
        designData.filter.clothingFitType.legalValues(getNestedFitTypeCodes(template))
        designData.filter.clothingCutType.legalValues(getNestedCutTypeCodes(template))

        var fitTypeMap = template.skuMap;
        _.any(fitTypeMap, function (cutTypeMap, fitTypeKey) {
            var fitType = cleanKey(fitTypeKey)
            designData.filter.clothingCutType.$itemMap = _.mapValues(designData.filter.clothingCutType.$itemMap, getCutTypeMapper(cutTypeMap))
            return _.any(cutTypeMap, function (skuData, cutTypeKey) {
                var cutType = cleanKey(cutTypeKey)
                if (skuData != null && skuData.sku === template.code) {
                    designData.filter.clothingFitType.value(fitType);
                    designData.filter.clothingCutType.value(cutType);
                    return true;
                }

            })
        })
    }
    function updateCutTypes(template) {
        designData.filter.clothingCutType.legalValues(getPantCutTypeCodes(template))

        var cutTypeMap = template.skuMap;
        designData.filter.clothingCutType.$itemMap = _.mapValues(designData.filter.clothingCutType.$itemMap, getCutTypeMapper(cutTypeMap))
        _.any(cutTypeMap, function (skuData, cutTypeKey) {
            var cutType = cleanKey(cutTypeKey)
            if (skuData != null && skuData.sku === template.code) {
                designData.filter.clothingCutType.value(cutType);
                return true;
            }
        })
    }

    function getNestedFitTypeCodes(template) {
        return _.chain(template.skuMap)
            .pick(function (fitTypeMap) {
                return _.any(fitTypeMap)
            })
            .keys()
            .map(function (fitTypeKey) {
                return cleanKey(fitTypeKey)
            })
            .value()
    }

    function getNestedCutTypeCodes(template) {
        return _.chain(template.skuMap)
            .transform(function(result, cutTypeMap) {
                _.each(cutTypeMap, function(skuData, cutTypeKey) {
                    if (skuData == null){
                        return
                    }
                    var cutType = cleanKey(cutTypeKey)
                    result.push(cutType)
                })
            }, [])
            .uniq()
            .value()
    }

    function getPantCutTypeCodes(template) {
        return _.chain(template.skuMap)
            .transform(function(result, skuData, cutTypeKey) {
                if(skuData == null){
                    return
                }
                var cutType = cleanKey(cutTypeKey)
                result.push(cutType)
            }, [])
            .uniq()
            .value()
    }

    return function(template) {
        if (designData.filter.clothingFitType) {
            updateFitAndCutTypes(template)
        } else if (designData.filter.clothingCutType) {
            updateCutTypes(template)
        }
    }
})
