'use strict';

angular.module('mainApp').factory('projectIdHandler', function (_, $q, projectLoader, projectSaver, dataModelVersion, designDataOutput, updateDesignData, previewUploader) {

    var _projectID,
        _designDataCache = {};

    function getProjectID() {
        return _projectID;
    }

    function unsetProjectID() {
        _projectID = null;
    }

    function loadProject(projectID) {
        if (!projectID) {
            return;
        }

        if (projectID === _projectID) {
            return;
        }

        _projectID = projectID;

        return projectLoader
                .load(projectID)
                .success(loadSuccess);
    }

    function saveProject() {
        var projectData = {
            'dataModelVersion': dataModelVersion,
            'designData': designDataOutput
        };

        if (_projectID !== undefined) {
            if (_.isEqual(designDataOutput, _designDataCache)) {
                var defer = $q.defer();

                defer.resolve({
                    'projectId': _projectID
                });

                return defer.promise;
            }
        }

        // delete generated shapshots
        previewUploader.resetData();

        _designDataCache = angular.copy(designDataOutput);

        return projectSaver
                .save(projectData)
                .success(saveSuccess);
    }

    function loadSuccess(data) {
        previewUploader.resetData();

        _designDataCache = data.designData;

        updateDesignData(data.designData, true);
    }

    function saveSuccess(data) {
        _projectID = data.projectId;
    }

    return {
        'getProjectID': getProjectID,
        'unsetProjectID': unsetProjectID,
        'loadProject': loadProject,
        'saveProject': saveProject
    }
});
