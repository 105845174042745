'use strict';
var projectConfig = require('data').config

angular.module('sdk').factory('_', function () {
    return require('lodash');
});

angular.module('mainApp', ['jarden', 'default-routes']);

angular.module('mainApp').value('dataModelVersion', '4.10.0');

angular.module('mainApp').value('imageServerPath', '//customizer-media.orangelv.com/rawlings-' + projectConfig.sportId + '-uniform/upload');

angular.module('mainApp').factory('loadSaveOrderConfig', function ($location) {
    return {
        saveUrl: $location.protocol() + '://customizer-api.orangelv.com/v2/rawlings/' + projectConfig.sportId + '-uniform/project/save',
        loadUrl: $location.protocol() + '://customizer-api.orangelv.com/v2/rawlings/' + projectConfig.sportId + '-uniform/project/{projectID}',
        orderUrl: $location.protocol() + '://customizer-api.orangelv.com/v2/rawlings/' + projectConfig.sportId + '-uniform/{vendor}/order/{projectID}',
        previewUrl: $location.protocol() + '://customizer-preview.orangelv.com/rawlings-' + projectConfig.sportId + '-uniform/{projectID}/{viewName}',
    }
});

angular.module('mainApp').factory('publicUrl', function ($location) {
    return $location.protocol() + '://' + $location.host()
});

angular.module('mainApp').value('projectTitle', require('data').config.projectTitle)
angular.module('mainApp').value('vendorConfig', require('data').config.vendorConfig)
angular.module('mainApp').value('projectConfig', require('data').config.projectConfig)
angular.module('mainApp').value('canvasConfig', require('data').config.canvasConfig)
angular.module('mainApp').value('assetConfig', require('data').config.assetConfig)

angular.module('mainApp').run(function ($rootScope, designData, productData) {
    $rootScope.designData = designData
    $rootScope.productData = productData
})
