'use strict'

angular.module('mainApp').config(function($provide) {
    $provide.decorator('designSummaryDirective', ['$delegate', function ($delegate) {
        //$delegate is array of all ng-click directive
        //in this case frist one is angular buildin ng-click
        //so we remove it.
        $delegate.shift()
        return $delegate
    }])
})

angular.module('mainApp').directive('designSummary', function (_, designData, previewService) {
    return {
        restrict: 'E',
        scope: {
            status: '@',
            togglable: '@',
            printable: '@',
        },
        replace: true,
        templateUrl: 'designSummary/main.html',
        controller: function($scope) {
            $scope.previewService = previewService

            $scope.sections = require('data/model').getSummary(designData)
        },
    }
})
