'use strict'

angular
    .module('mainApp')
    .factory('autoWatcher', function(_, $timeout, $rootScope, designData) {
        var scope = $rootScope.$new(true)
        scope.designData = designData

        var whitelists = require('data').whitelists

        var config = require('data').config
        var BASEBALL = config.sportId === 'baseball'
        var SOFTBALL = config.sportId === 'softball'
        var FOOTBALL = config.sportId === 'football'

        $timeout(function() {
            // JERSEY
            // COLOR AREAS
            SOFTBALL && autoUpdateColorAreas(designData.jersey.colorAreas)
            _.each(designData.jersey.colorAreas, autoToggleAreaFillColors)

            // OPTIONS
            _.each(designData.jersey.options, autoToggleOptionColors)

            // DECORATIONS
            autoPreventSamePlacementValue(
                _.flatten([
                    designData.jersey.decoration.teamName.placement,
                    designData.jersey.decoration.playerName.placement,
                    _.pluck(designData.jersey.decoration.number, 'placement'),
                    _.pluck(designData.jersey.decoration.logo, 'placement'),
                    !FOOTBALL &&
                        _.pluck(
                            designData.jersey.decoration.watermarkLogo,
                            'placement'
                        ),
                    !FOOTBALL &&
                        designData.jersey.decoration.watermarkText.placement,
                ])
            )

            _.each(
                _.flatten([
                    designData.jersey.decoration.teamName,
                    designData.jersey.decoration.playerName,
                    _.values(designData.jersey.decoration.number),
                ]),
                autoFixLetering
            )

            autoSync(
                [
                    designData.jersey.decoration.number.front,
                    designData.jersey.decoration.number.back,
                    designData.jersey.decoration.number.sleeve,
                ],
                ['text']
            )

            autoUnset([
                designData.jersey.decoration.logo.stock.placement,
                designData.jersey.decoration.logo.custom.placement,
            ])

            !FOOTBALL &&
                autoUnset([
                    designData.jersey.decoration.watermarkLogo.stock.placement,
                    designData.jersey.decoration.watermarkLogo.custom.placement,
                ])
            !FOOTBALL &&
                autoPreventWatermarkOnPatternFill(
                    designData.jersey.colorAreas.area2.pattern,
                    [
                        designData.jersey.decoration.watermarkText.placement,
                        designData.jersey.decoration.watermarkLogo.stock
                            .placement,
                        designData.jersey.decoration.watermarkLogo.custom
                            .placement,
                    ]
                )
            FOOTBALL &&
                autouseDecoPlacement([
                    designData.jersey.decoration.number.front,
                    designData.jersey.decoration.logo.stock,
                    designData.jersey.decoration.logo.custom,
                ])

            autoUpdateLogoColorAvailability(
                designData.jersey.decoration.logo.stock
            )

            // PANT
            // COLOR AREAS
            SOFTBALL && autoUpdateColorAreas(designData.pant.colorAreas)
            _.each(designData.pant.colorAreas, autoToggleAreaFillColors)

            // OPTIONS
            _.each(designData.pant.options, autoToggleOptionColors)

            // BASEBALL & SOFTBALL OPTIONS
            if (!FOOTBALL) {
                autoSync(
                    [
                        designData.pant.options.OP4,
                        designData.pant.options.OP4_2,
                        designData.pant.options.OP4B,
                        designData.pant.options.OP4C,
                    ],
                    ['color_1', 'color_2', 'color_3']
                )

                autoUnset([
                    designData.pant.options.OP4 && designData.pant.options.OP4.type,
                    designData.pant.options.OP4_2 && designData.pant.options.OP4_2.type,
                    designData.pant.options.OP4B &&designData.pant.options.OP4B.type,
                    designData.pant.options.OP4C &&designData.pant.options.OP4C.type,
                ])

                autoUnset([
                    designData.pant.options.OP15.type,
                    designData.pant.options.RIBWST.type,
                ])
            }

            // SOFTBALL DECORATIONS
            if (SOFTBALL) {
                autoPreventSamePlacementValue(
                    _.flatten([
                        designData.pant.decoration.teamName.placement,
                        _.pluck(designData.pant.decoration.logo, 'placement'),
                    ])
                )

                autoFixOutline2(designData.pant.decoration.teamName)

                autoUnset([
                    designData.pant.decoration.logo.stock.placement,
                    designData.pant.decoration.logo.custom.placement,
                ])

                autoUpdateLogoColorAvailability(
                    designData.pant.decoration.logo.stock
                )
            }

            // FOOTBALL DECORATIONS
            if (FOOTBALL) {
                autoPreventSamePlacementValue(
                    _.flatten([
                        designData.pant.decoration.teamName.placement,
                        _.pluck(designData.pant.decoration.number, 'placement'),
                        _.pluck(designData.pant.decoration.logo, 'placement'),
                    ])
                )

                autoFixLetering(designData.pant.decoration.teamName)

                autoUnset([
                    designData.pant.decoration.logo.stock.placement,
                    designData.pant.decoration.logo.custom.placement,
                ])
                autoUnset([
                    designData.pant.decoration.upperLogo.stock.placement,
                    designData.pant.decoration.upperLogo.custom.placement,
                ])

                autouseDecoPlacement([
                    designData.jersey.decoration.logo.stock,
                    designData.jersey.decoration.logo.custom,
                ])

                autoUpdateLogoColorAvailability(
                    designData.pant.decoration.logo.stock
                )
                autoUpdateLogoColorAvailability(
                    designData.pant.decoration.upperLogo.stock
                )
            }
        })

        function autoUpdateColorAreas(colorAreas) {
            _.each(colorAreas, function(colorAreaProps) {
                scope.$watch(
                    'designData.' + colorAreaProps.fabric.$path + '.value()',
                    function(fabric) {
                        _.each(colorAreaProps, function(prop, field) {
                            if (_.contains(['fabric', 'pattern'], field)) {
                                return
                            }
                            if(prop._exception) {
                              return
                            }
                            prop.legalValues(whitelists.colors[fabric])
                        })
                    }
                )
            })
        }

        function autoToggleAreaFillColors(areaFill) {
            autoToggleColors(areaFill.pattern, areaFill)
        }

        function autoToggleOptionColors(option) {
            if (option.type == null) {
                return
            }
            autoToggleColors(option.type, option)
        }

        function autoToggleColors(colorCountHolderProp, colorPropList) {
            var colorPropList = _.transform(colorPropList, function(
                result,
                prop,
                key
            ) {
                if (_.startsWith(key, 'color_')) {
                    var colorId = parseInt(key.replace('color_', ''))
                    result[colorId] = prop
                }
            })
            scope.$watch(
                'designData.' + colorCountHolderProp.$path + '.item()',
                function(colorCountHolder) {
                    _.each(colorPropList, function(prop, colorId) {
                        prop.isDisabled(
                            'pattern',
                            !colorCountHolder ||
                                parseInt(colorId) > colorCountHolder.colorCount
                        )
                    })
                }
            )
        }

        function autoPreventSamePlacementValue(placementList) {
            placementList = _.compact(placementList)
            _.each(placementList, function(placement) {
                var otherPlacementList = _.without(placementList, placement)
                scope.$watch(
                    'designData.' + placement.$path + '.value()',
                    function(value) {
                        if (!value || value === 'none') {
                            return
                        }

                        _.each(otherPlacementList, function(otherPlacement) {
                            if (
                                otherPlacement.value() === value ||
                                _.contains(
                                    placement.item().conflictsWith,
                                    otherPlacement.value()
                                )
                            ) {
                                otherPlacement.value('none')
                            }
                        })
                    }
                )
            })
        }

        function autoPreventWatermarkOnPatternFill(
            fillAreaPattern,
            decoPlacements
        ) {
            scope.$watch(
                'designData.' + fillAreaPattern.$path + '.value()',
                function(pattern) {
                    var isDisabled = pattern !== 'solidColor'
                    _.each(decoPlacements, function(decoPlacement) {
                        decoPlacement.isDisabled(
                            'body fill pattern',
                            isDisabled
                        )
                    })
                }
            )
        }

        function autoUpdateLogoColorAvailability(deco) {
            var designProp = deco.design
            var color1Prop = deco.fill.color_1
            var color2Prop = deco.fill.color_2
            var color3Prop = deco.fill.color_3

            scope.$watch(
                'designData.' + designProp.$path + '.value()',
                function(value) {
                    var design = designProp.item()
                    var colorCount =
                        value == null || value == 'none' ? 0 : design.colorCount
                    color1Prop.isDisabled('colorCount < 1', colorCount < 1)
                    color2Prop.isDisabled('colorCount < 2', colorCount < 2)
                    color3Prop.isDisabled('colorCount < 3', colorCount < 3)

                    if (deco.size) {
                        deco.size.$legalValuesByDesign = design
                            ? design.sizeList
                            : undefined
                        updateSizeList(deco.size)
                    }
                }
            )
        }

        function autoFixLetering(letteringData) {
            autoToggleColors(letteringData.fill.pattern, letteringData.fill)
            autoFixOutline2(letteringData)
        }

        function autoFixOutline2(letteringData) {
            var outline1Prop = letteringData.outline_1
            var outline2Prop = letteringData.outline_2

            scope.$watch(
                'designData.' + outline1Prop.$path + '.value()',
                function(value) {
                    if (!value) {
                        return
                    }

                    outline2Prop.isDisabled('outline1', value === 'none')
                }
            )
        }

        /**
         * @type objects {object[]}
         * @type paths {string[]}
         * @type guardFn {Function}
         */
        function autoSync(objects, paths, guardFn) {
            objects = _.compact(objects)
            _.each(objects, function(object) {
                var otherProps = _.without(objects, object)
                _.each(paths, function(path) {
                    var source = _.get(object, path)
                    scope.$watch(
                        'designData.' + source.$path + '.value()',
                        function(value) {
                            _.each(otherProps, function(prop) {
                                var dest = _.get(prop, path)
                                if (
                                    dest.value() !== value &&
                                    (guardFn == null || guardFn())
                                ) {
                                    dest.value(value)
                                }
                            })
                        }
                    )
                })
            })
        }

        /**
         * @type props {LimitedProperty}
         */
        function autoUnset(props) {
            props = _.compact(props)
            _.each(props, function(prop) {
                var otherProps = _.without(props, prop)
                scope.$watch('designData.' + prop.$path + '.value()', function(
                    value
                ) {
                    if (!value || value === 'none') {
                        return
                    }

                    _.each(otherProps, function(otherProp) {
                        otherProp.value('none')
                    })
                })
            })
        }

        function autouseDecoPlacement(deco) {
            _.each(deco, function(deco) {
                scope.$watch(
                    'designData.' + deco.placement.$path + '.item()',
                    function(placement) {
                        if (!placement || placement.code === 'none') {
                            deco.size.isDisabled('placement', true)
                            deco.design &&
                                deco.design.isDisabled('placement', true)
                            deco.fill &&
                                deco.fill.color_1.isDisabled('placement', true)
                            return
                        }

                        deco.size.isDisabled('placement', false)
                        deco.design &&
                            deco.design.isDisabled('placement', false)
                        deco.fill &&
                            deco.fill.color_1.isDisabled('placement', false)

                        deco.size.$legalValuesByPlacement =
                            placement.limitations.sizeList
                        updateSizeList(deco.size)
                    }
                )
            })
        }

        function updateSizeList(size, sizeListByPlacement, sizeListByDesign) {
            var byDesign = size.$legalValuesByDesign
            var byPlacement = size.$legalValuesByPlacement
            var initial = size.$initialLegalValueList

            if (byDesign && !byDesign.length) {
                byDesign = null
            }
            if (byDesign && !byDesign.length) {
                byPlacement = null
            }

            size.legalValues(byDesign || byPlacement || initial)
        }
    })

/* eslint-disable */
angular.module('mainApp').run(function(autoWatcher) {
    // force to activate autoWatcher
})
