'use strict'

angular
    .module('mainApp')
    .factory('decoWatcher', function(
        _,
        designData,
        designDataUtils,
        selectors
    ) {
        var config = require('data').config
        var FOOTBALL = config.sportId === 'football'

        var sheets = require('data').sheets
        var whitelists = require('data').whitelists

        function updateDecoLimitationsBBSB(
            productData,
            template,
            productType,
            decoSet
        ) {
            if (!decoSet) {
                return
            }

            function withoutPlacements(placements, deco) {
                var placement = _.difference(deco.placement, placements)
                return _.defaults(
                    {
                        placement: placement,
                        isAvailable:
                            deco.isAvailable &&
                            !_.isEmpty(_.without(placement, 'none')),
                    },
                    deco
                )
            }

            function withoutSleevePlacements(preventSleeves, deco) {
                if (!preventSleeves) {
                    return deco
                }
                return withoutPlacements(['17L', '17R', '17LR', '18LR'], deco)
            }

            function without27Placements(preventSleeves, deco) {
                if (!preventSleeves) {
                    return deco
                }
                return withoutPlacements(['27L', '27R', '27LR'], deco)
            }

            function withoutDisabledDeco(decoName, deco) {
                if (!_.includes(disabledDecs, decoName)) {
                    return deco
                }
                return withoutPlacements(['none'], deco)
            }

            function withPlacements(placements, deco) {
                if (deco.placement !== undefined) {
                    return deco
                }
                return _.defaults({ placement: placements }, deco)
            }

            function withSizes(sizes, deco) {
                if (deco.size !== undefined) {
                    return deco
                }
                return _.defaults({ size: sizes }, deco)
            }

            function withLogoDesigns(logos, deco) {
                if (deco.design !== undefined) {
                    return deco
                }
                return _.defaults({ design: logos }, deco)
            }

            function applyLegalValues(deco, legalValues) {
                if (
                    _.isArray(legalValues.placement) &&
                    _.isEmpty(_.without(legalValues.placement, 'none'))
                ) {
                    legalValues = {
                        isAvailable: false,
                    }
                }
                designDataUtils.setLegalValuesDeep(deco, legalValues)
            }

            if (_.isEqual(productType, 'jersey')) {
                var limitations = decoSet.limitations[productType]
                var decoration = productData.decoration
                var decoDefs = sheets.decos.jersey
                var disabledDecs =
                    (template.decoration &&
                        template.decoration.disabledDecos) ||
                    []

                var isSleeveless = template.asset.isSleeveless

                var teamNameSizes = decoDefs.teamName.sizeList

                var logoDesigns = whitelists.logos.all

                decoration.teamName &&
                    applyLegalValues(
                        decoration.teamName,
                        withSizes(teamNameSizes, limitations.teamName)
                    )
                decoration.playerName &&
                    applyLegalValues(
                        decoration.playerName,
                        limitations.playerName
                    )
                decoration.number &&
                    decoration.number.front &&
                    applyLegalValues(
                        decoration.number.front,
                        limitations.number.front
                    )
                decoration.number &&
                    decoration.number.back &&
                    applyLegalValues(
                        decoration.number.back,
                        limitations.number.back
                    )
                decoration.number &&
                    decoration.number.sleeve &&
                    applyLegalValues(
                        decoration.number.sleeve,
                        withoutSleevePlacements(
                            isSleeveless,
                            withPlacements(
                                decoDefs.sleeveNumber.placementList,
                                limitations.number.sleeve
                            )
                        )
                    )
                decoration.logo &&
                    decoration.logo.stock &&
                    applyLegalValues(
                        decoration.logo.stock,
                        withLogoDesigns(
                            logoDesigns,
                            withoutSleevePlacements(
                                isSleeveless,
                                withPlacements(
                                    decoDefs.stockLogo.placementList,
                                    limitations.logo.stock
                                )
                            )
                        )
                    )
                decoration.logo &&
                    decoration.logo.custom &&
                    applyLegalValues(
                        decoration.logo.custom,
                        withoutSleevePlacements(
                            isSleeveless,
                            withPlacements(
                                decoDefs.customLogo.placementList,
                                limitations.logo.custom
                            )
                        )
                    )
                decoration.watermarkLogo &&
                    applyLegalValues(
                        decoration.watermarkLogo.stock,
                        withLogoDesigns(
                            whitelists.logos.watermark,
                            withoutDisabledDeco(
                                'watermarkLogo.stock',
                                limitations.watermarkLogo.stock
                            )
                        )
                    )
                decoration.watermarkLogo &&
                    applyLegalValues(
                        decoration.watermarkLogo.custom,
                        withoutDisabledDeco(
                            'watermarkLogo.custom',
                            limitations.watermarkLogo.custom
                        )
                    )
                decoration.watermarkText &&
                    applyLegalValues(
                        decoration.watermarkText,
                        withoutDisabledDeco(
                            'watermarkText',
                            limitations.watermarkText
                        )
                    )

                decoration.sleeveStripes &&
                    applyLegalValues(
                        decoration.sleeveStripes,
                        limitations.sleeveStripes
                    )
            }

            if (_.isEqual(productType, 'pant')) {
                limitations = decoSet.limitations[productType]
                decoration = productData.decoration
                decoDefs = sheets.decos.pant || {}

                teamNameSizes = decoDefs.teamName
                    ? decoDefs.teamName.sizeList
                    : []

                logoDesigns = whitelists.logos.all

                var preventsPlacement27 =
                    _.has(template.options, 'hasPlacement27') &&
                    !template.options.hasPlacement27
                decoration.teamName &&
                    designDataUtils.setLegalValuesDeep(
                        decoration.teamName,
                        withSizes(
                            teamNameSizes,
                            without27Placements(
                                preventsPlacement27,
                                withPlacements(
                                    decoDefs.teamName.placementList,
                                    limitations.teamName
                                )
                            )
                        )
                    )
                decoration.logo &&
                    decoration.logo.stock &&
                    designDataUtils.setLegalValuesDeep(
                        decoration.logo.stock,
                        withLogoDesigns(
                            logoDesigns,
                            without27Placements(
                                preventsPlacement27,
                                withPlacements(
                                    decoDefs.stockLogo.placementList,
                                    limitations.logo.stock
                                )
                            )
                        )
                    )
                decoration.logo &&
                    decoration.logo.custom &&
                    designDataUtils.setLegalValuesDeep(
                        decoration.logo.custom,
                        without27Placements(
                            preventsPlacement27,
                            withPlacements(
                                decoDefs.customLogo.placementList,
                                limitations.logo.custom
                            )
                        )
                    )
                decoration.upperLogo &&
                    decoration.upperLogo.stock &&
                    designDataUtils.setLegalValuesDeep(
                        decoration.upperLogo.stock,
                        withLogoDesigns(
                            logoDesigns,
                            without27Placements(
                                preventsPlacement27,
                                withPlacements(
                                    decoDefs.upperLogo.placementList,
                                    limitations.logo.stock
                                )
                            )
                        )
                    )
                decoration.upperLogo &&
                    decoration.upperLogo.custom &&
                    designDataUtils.setLegalValuesDeep(
                        decoration.upperLogo.custom,
                        without27Placements(
                            preventsPlacement27,
                            withPlacements(
                                decoDefs.upperLogo.placementList,
                                limitations.logo.custom
                            )
                        )
                    )
            }
        }

        var decoTypeIds = [
            'teamName',
            'playerName',
            'numberFront',
            'numberBack',
            'numberSleeve',
            'stockLogo',
            'customLogo',
            'watermarkLogo',
            'watermarkText',
        ]

        var sizesByPlacementByTypeByPackage = _.chain(sheets.decoPlacementSizes)
            .groupBy('decoPackageId')
            .mapValues(function(decoPlacementSizes, packageId) {
                return _.transform(
                    decoTypeIds,
                    function(result, decoTypeId) {
                        result[decoTypeId] = _.transform(
                            decoPlacementSizes,
                            function(result, row) {
                                var sizes = row.legalValues[decoTypeId].height
                                if (!sizes.length) {
                                    return
                                }
                                result[row.name] = sizes
                            },
                            {}
                        )
                    },
                    {}
                )
            })
            .value()

        function updateDecoLimitationsFB(
            productData,
            template,
            productType,
            decoSet
        ) {
            if (!decoSet) {
                return
            }

            var decoPackageId = decoSet.code
            var sizesByPlacementByType =
                sizesByPlacementByTypeByPackage[decoPackageId] || {}

            function applyLegalValues(deco, legalValues) {
                if (
                    _.isArray(legalValues.placement) &&
                    _.isEmpty(_.without(legalValues.placement, 'none'))
                ) {
                    legalValues = {
                        isAvailable: false,
                    }
                }
                designDataUtils.setLegalValuesDeep(deco, legalValues)
            }

            var decoration = productData.decoration
            var limitations = decoSet.legalValues

            var disabledDecoIds = template.disabledDecoIds
            var disabledDecoPlacementIds = template.disabledDecoPlacementIds

            var legalValueCombos = [
                [decoration.teamName, limitations.teamName, 'teamName'],
                [decoration.playerName, limitations.playerName, 'playerName'],
                [
                    decoration.number && decoration.number.front,
                    limitations.number,
                    'numberFront',
                ],
                [
                    decoration.number && decoration.number.back,
                    limitations.number,
                    'numberBack',
                ],
                [
                    decoration.number && decoration.number.sleeve,
                    limitations.number,
                    'numberSleeve',
                ],
                [
                    decoration.logo && decoration.logo.stock,
                    limitations.stockLogo,
                    'stockLogo',
                ],
                [
                    decoration.logo && decoration.logo.custom,
                    limitations.customLogo,
                    'customLogo',
                ],
                [
                    decoration.watermarkLogo && decoration.watermarkLogo.stock,
                    limitations.watermarkLogo,
                    'watermarkLogo',
                ],
                [
                    decoration.watermarkLogo && decoration.watermarkLogo.custom,
                    limitations.watermarkLogo,
                    'watermarkLogo',
                ],
                [
                    decoration.watermarkText,
                    limitations.watermarkText,
                    'watermarkText',
                ],
                [
                    decoration.upperLogo && decoration.upperLogo.stock,
                    limitations.stockLogo,
                    'stockLogo',
                ],
                [
                    decoration.upperLogo && decoration.upperLogo.custom,
                    limitations.customLogo,
                    'customLogo',
                ],
            ]

            _.forEach(legalValueCombos, function(legalValueCombo) {
                var deco = legalValueCombo[0]
                var decoLegalValues = legalValueCombo[1]
                // var sizesByPlacement = legalValueCombo[2]
                var decoId = legalValueCombo[2]

                var sizesByPlacement = sizesByPlacementByType[decoId]

                if (!deco) {
                    return
                }

                var disabledByGarment = _.includes(disabledDecoIds, decoId)
                deco.placement.isDisabled(
                    'disabledByGarment',
                    disabledByGarment
                )
                if (disabledByGarment) {
                    return
                }

                var disabledByDecoPackage =
                    !decoLegalValues || !decoLegalValues.isAvailable
                deco.placement.isDisabled(
                    'disabledByDecoPackage',
                    disabledByDecoPackage
                )
                if (disabledByDecoPackage) {
                    return
                }

                if (deco.placement) {
                    var legalValues = _.keys(sizesByPlacement)
                    if (disabledDecoPlacementIds.length) {
                        legalValues = _.difference(
                            legalValues,
                            disabledDecoPlacementIds
                        )
                    }
                    legalValues.unshift('none')
                    if (deco.size) {
                        deco.size.$legalValuesByPlacement = sizesByPlacement
                    }
                    deco.placement.legalValues(legalValues)
                    deco.placement.isDisabled(
                        '_.isEmpty(legalValues)',
                        _.isEmpty(legalValues)
                    )
                }

                if (deco.font) {
                    var setId = decoLegalValues.fontSetId
                    var legalValues = whitelists.fonts[setId]
                    deco.font.legalValues(legalValues)
                }

                if (deco.layout) {
                    var setId = decoLegalValues.layoutSetId
                    var legalValues = whitelists.layouts[setId]
                    deco.layout.legalValues(legalValues)
                }

                if (deco.fill) {
                    if (deco.fill.pattern) {
                        var setId = decoLegalValues.fill.patternSetId
                        var legalValues = whitelists.designPatterns[setId]
                        deco.fill.pattern.legalValues(legalValues)
                    }

                    if (deco.fill.color_1) {
                        var setId = decoLegalValues.fill.colorSetId
                        var legalValues = whitelists.colors[setId]
                        var legalValuesOptional =
                            whitelists.colors[setId + 'Optional']
                        deco.fill.color_1 &&
                            deco.fill.color_1.legalValues(legalValues)
                        deco.fill.color_2 &&
                            deco.fill.color_2.legalValues(legalValues)
                        deco.fill.color_3 &&
                            deco.fill.color_3.legalValues(legalValues)
                        deco.fill.color_4 &&
                            deco.fill.color_4.legalValues(legalValues)
                        deco.outline_1 &&
                            deco.outline_1.legalValues(legalValuesOptional)
                        deco.outline_2 &&
                            deco.outline_2.legalValues(legalValuesOptional)
                    }
                }

                var tailDeco = deco.tailing
                if (tailDeco) {
                    tailDeco.enabled.isDisabled(
                        !decoLegalValues.tail,
                        '!decoLegalValues.tail'
                    )

                    if (tailDeco.font) {
                        var setId = decoLegalValues.fontSetId
                        var legalValues = whitelists.fonts[setId]
                        tailDeco.font.legalValues(legalValues)
                    }

                    if (tailDeco.layout) {
                        tailDeco.layout.legalValues('straight')
                    }

                    if (tailDeco.size) {
                        tailDeco.size.legalValues(['0.75', '1'])
                    }

                    if (tailDeco.fill) {
                        var setId = decoLegalValues.fill.colorSetId
                        var legalValues = whitelists.colors[setId]
                        var legalValuesOptional =
                            whitelists.colors[setId + 'Optional']
                        tailDeco.fill && tailDeco.fill.legalValues(legalValues)
                        tailDeco.outline &&
                            tailDeco.outline.legalValues(legalValuesOptional)
                    }
                }
            })

            if (
                productData.decoration.teamName &&
                productData.decoration.teamName.tailing
            ) {
                function updateTailingAvaiablitiy() {
                    var productData = designData.jersey
                    var decoration = productData.decoration.teamName
                    var legalValues = whitelists.toggles.all

                    if (
                        !decoration.font.item().canHaveTailing ||
                        !decoration.layout.item().canHaveTailing
                    ) {
                        legalValues = _.without(legalValues, 'on')
                    }

                    productData.decoration.teamName.tailing.enabled.legalValues(
                        legalValues
                    )
                }
                selectors.watch(
                    'designData.jersey.decoration.teamName.font.value()',
                    updateTailingAvaiablitiy
                )
                selectors.watch(
                    'designData.jersey.decoration.teamName.layout.value()',
                    updateTailingAvaiablitiy
                )
            }
        }

        var updateDecoLimitations = FOOTBALL
            ? updateDecoLimitationsFB
            : updateDecoLimitationsBBSB

        selectors.watch('designData.clothingType.value()', function(
            clothingTypeId
        ) {
            selectors.watcherScope.calc.productData = designData[clothingTypeId]
        })

        selectors.watchGroup(
            [
                'designData.clothingType.value()',
                'designData.designTemplate.item()',
                'designData.filter.clothingCutType.value()',
            ],
            function(clothingTypeId, template, cutTypeId) {
                var decoSets = template.decoration.decorationPackages
                var decoSetIds = _.isArray(decoSets)
                    ? decoSets
                    : decoSets[cutTypeId]
                designData[clothingTypeId].decoration.type.legalValues(
                    decoSetIds
                )
                if (designData.jerseyInside && clothingTypeId === 'jersey') {
                    designData.jerseyInside.decoration.type.legalValues(
                        decoSetIds
                    )
                }
            }
        )

        selectors.watchGroup(
            [
                'designData.clothingType.value()',
                'designData.designTemplate.item()',
                'calc.productData.decoration.type.item()',
            ],
            function(clothingTypeId, template, decoSet) {
                updateDecoLimitations(
                    designData[clothingTypeId],
                    template,
                    clothingTypeId,
                    decoSet
                )
                if (designData.jerseyInside && clothingTypeId === 'jersey') {
                    updateDecoLimitations(
                        designData.jerseyInside,
                        template,
                        clothingTypeId,
                        decoSet
                    )
                }
            }
        )

        selectors.watchGroup(
            [
                'designData.jersey.decoration.teamName.font.item()',
                'designData.jersey.decoration.teamName.layout.item()',
            ],
            function(font, layout) {
                var productData = designData.jersey
                var decoration = productData.decoration
                var legalValues = whitelists.toggles.all
                if (!decoration.teamName) {
                    return
                }

                if (
                    !font ||
                    !layout ||
                    !font.canHaveTailing ||
                    !layout.canHaveTailing
                ) {
                    legalValues = _.without(legalValues, 'on')
                }

                decoration.teamName.tailing.enabled.legalValues(legalValues)
            }
        )
    })

/* eslint-disable */
angular.module('mainApp').run(function(decoWatcher) {
    // force to activate decoWatcher
})
