'use strict'

angular.module('mainApp').factory('selectButtonMap', function (_) {
    var labels = {
        clothingType: 'Jersey or Pant',
        clothingCollection: 'Custom, Sublimated or Stock',
        clothingStyle: 'Style',
    }

    return _.map(labels, function(name, code) {
        return {
            'code': code,
            'name': name,
        }
    })
})
