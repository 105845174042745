'use strict'

angular
    .module('mainApp')
    .factory('menuDataMap', function(_, designData, filterWatcher) {
        var config = require('data').config

        var BASEBALL = config.sportId === 'baseball'
        var SOFTBALL = config.sportId === 'softball'
        var FOOTBALL = config.sportId === 'football'

        function getBase() {
            return {
                select: {
                    id: 'select',
                    title: 'Select',
                    template: 'tabs/select.html',
                },
                fabricColors: {
                    id: 'fabricColors',
                    template: 'tabs/fabricColors.html',
                },
                options: {
                    id: 'options',
                    title: 'Glove options',
                    template: 'tabs/options.html',
                },
                decorate: {
                    id: 'decorate',
                    title: 'Personalize',
                    template: 'tabs/decoration.html',
                },

                clothingType: {
                    id: 'clothingType',
                    parent: 'select',
                    template: 'menu/clothing-type.html',
                    clothingType: designData.filter.clothingType,
                },
                clothingQuality: FOOTBALL && {
                    id: 'clothingQuality',
                    parent: 'select',
                    template: 'menu/filter.html',
                    data: designData.filter.clothingQuality,
                },
                clothingCollection: {
                    id: 'clothingCollection',
                    parent: 'select',
                    template: 'menu/clothing-collection.html',
                    collections: designData.filter.clothingCollection,
                },
                clothingCutType: {
                    id: 'clothingCutType',
                    parent: 'options',
                    template: 'menu/filter.html',
                    data: designData.filter.clothingCutType,
                },
                clothingFitType: {
                    id: 'clothingFitType',
                    parent: 'options',
                    template: 'menu/filter.html',
                    data: designData.filter.clothingFitType,
                },
                clothingStyle: {
                    id: 'clothingStyle',
                    parent: 'select',
                    template: 'menu/clothing-style.html',
                    filter: filterWatcher,
                },
            }
        }

        function getJersey(productData) {
            return {
                area1: {
                    id: 'area1',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area1,
                    fillPattern: productData.colorAreas.area1.pattern,
                    fabric: productData.colorAreas.area1.fabric,
                },
                area2: {
                    id: 'area2',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area2,
                    fillPattern: productData.colorAreas.area2.pattern,
                    fabric: productData.colorAreas.area2.fabric,
                },
                area3: {
                    id: 'area3',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area3,
                    fillPattern: productData.colorAreas.area3.pattern,
                    fabric: productData.colorAreas.area3.fabric,
                },
                area4: {
                    id: 'area4',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area4,
                    fillPattern: productData.colorAreas.area4.pattern,
                    fabric: productData.colorAreas.area4.fabric,
                },
                area5: {
                    id: 'area5',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area5,
                    fillPattern: productData.colorAreas.area5.pattern,
                    fabric: productData.colorAreas.area5.fabric,
                },
                area6: {
                    id: 'area6',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area6,
                    fillPattern: productData.colorAreas.area6.pattern,
                    fabric: productData.colorAreas.area6.fabric,
                },
                area7: {
                    id: 'area7',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area7,
                    fillPattern: productData.colorAreas.area7.pattern,
                    fabric: productData.colorAreas.area7.fabric,
                },
                area8: FOOTBALL && {
                    id: 'area8',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area8,
                    fillPattern: productData.colorAreas.area8.pattern,
                    fabric: productData.colorAreas.area8.fabric,
                },
                area9: FOOTBALL && {
                    id: 'area9',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area9,
                    fillPattern: productData.colorAreas.area9.pattern,
                    fabric: productData.colorAreas.area9.fabric,
                },
                area10: FOOTBALL && {
                    id: 'area10',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area10,
                    fillPattern: productData.colorAreas.area10.pattern,
                    fabric: productData.colorAreas.area10.fabric,
                },

                OP1: (BASEBALL || SOFTBALL) && {
                    id: 'decorationOption1',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.OP1,
                    fillPattern: productData.options.OP1.type,
                },
                OP2: (BASEBALL || SOFTBALL) && {
                    id: 'decorationOption2',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.OP2,
                    fillPattern: productData.options.OP2.type,
                },
                OP2_5: BASEBALL && {
                    id: 'decorationOption25',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.OP2_5,
                    fillPattern: productData.options.OP2_5.type,
                },
                OP3: (BASEBALL || SOFTBALL) && {
                    id: 'decorationOption3',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.OP3,
                    fillPattern: productData.options.OP3.type,
                },
                CLSU: FOOTBALL && {
                    id: 'CLSU',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.CLSU,
                    fillPattern: productData.options.CLSU.type,
                },
                sleeveStripes: FOOTBALL && {
                    id: 'sleeveStripes',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.sleeveStripes,
                    fillPattern: productData.options.sleeveStripes.type,
                },
                ribKnitCollar: (BASEBALL || SOFTBALL) && {
                    id: 'ribKnitCollar',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.ribKnitCollar,
                    fillPattern: productData.options.ribKnitCollar.type,
                },
                ribKnitCuff: (BASEBALL || SOFTBALL) && {
                    id: 'ribKnitCuff',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.ribKnitCuff,
                    fillPattern: productData.options.ribKnitCuff.type,
                },
                sublimatedCollar: (BASEBALL || SOFTBALL) && {
                    id: 'sublimatedCollar',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.sublimatedCollar,
                    fillPattern: productData.options.sublimatedCollar.type,
                },
                sublimatedCuff: (BASEBALL || SOFTBALL) && {
                    id: 'sublimatedCuff',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.sublimatedCuff,
                    fillPattern: productData.options.sublimatedCuff.type,
                },
                buttonColor: (BASEBALL || SOFTBALL) && {
                    id: 'buttonColor',
                    parent: 'options',
                    template: 'submenu/colors.html',
                    colors: productData.options.buttonColor,
                },
                logoColor: {
                    id: 'logoColor',
                    parent: 'options',
                    template: 'submenu/colors.html',
                    colors: productData.options.logoColor,
                },

                type: {
                    id: 'type',
                    parent: 'decorate',
                    template: 'submenu/list.html',
                    list: productData.decoration.type,
                },
                teamName: {
                    id: 'teamName',
                    parent: 'decorate',
                    template: 'menu/lettering.html',
                    text: productData.decoration.teamName.text,
                    decoData: productData.decoration.teamName,
                },
                playerName: {
                    id: 'playerName',
                    parent: 'decorate',
                    template: 'menu/lettering.html',
                    text: productData.decoration.playerName.text,
                    decoData: productData.decoration.playerName,
                },
                frontNumber: {
                    id: 'frontNumber',
                    parent: 'decorate',
                    template: 'menu/lettering.html',
                    number: productData.decoration.number.front.text,
                    decoData: productData.decoration.number.front,
                },
                backNumber: {
                    id: 'backNumber',
                    parent: 'decorate',
                    template: 'menu/lettering.html',
                    number: productData.decoration.number.back.text,
                    decoData: productData.decoration.number.back,
                },
                sleeveNumber: {
                    id: 'sleeveNumber',
                    parent: 'decorate',
                    template: 'menu/lettering.html',
                    number: productData.decoration.number.sleeve.text,
                    decoData: productData.decoration.number.sleeve,
                },
                stockLogo: {
                    id: 'stockLogo',
                    parent: 'decorate',
                    template: 'menu/logo.html',
                    design: productData.decoration.logo.stock.design,
                    size: productData.decoration.logo.stock.size,
                    placement: productData.decoration.logo.stock.placement,
                    colors: productData.decoration.logo.stock.fill,
                },
                customLogo: {
                    id: 'customLogo',
                    parent: 'decorate',
                    template: 'menu/logo.html',
                    customFile: productData.decoration.logo.custom.customFile,
                    size: productData.decoration.logo.custom.size,
                    placement: productData.decoration.logo.custom.placement,
                },
                watermarkText: (BASEBALL || SOFTBALL) && {
                    id: 'watermarkText',
                    parent: 'decorate',
                    template: 'menu/lettering.html',
                    text: productData.decoration.watermarkText.text,
                    decoData: productData.decoration.watermarkText,
                },

                stockWatermarkLogo: (BASEBALL || SOFTBALL) && {
                    id: 'stockWatermarkLogo',
                    parent: 'decorate',
                    template: 'menu/logo.html',
                    design: productData.decoration.watermarkLogo.stock.design,
                    size: productData.decoration.watermarkLogo.stock.size,
                    placement:
                        productData.decoration.watermarkLogo.stock.placement,
                    colors: productData.decoration.watermarkLogo.stock.fill,
                },
                customWatermarkLogo: (BASEBALL || SOFTBALL) && {
                    id: 'customWatermarkLogo',
                    parent: 'decorate',
                    template: 'menu/logo.html',
                    customFile:
                        productData.decoration.watermarkLogo.custom.customFile,
                    size: productData.decoration.watermarkLogo.custom.size,
                    placement:
                        productData.decoration.watermarkLogo.custom.placement,
                },
            }
        }

        function getPant(productData) {
            return {
                area1: {
                    id: 'area1',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area1,
                    fillPattern: productData.colorAreas.area1.pattern,
                    fabric: productData.colorAreas.area1.fabric,
                },
                area2: {
                    id: 'area2',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area2,
                    fillPattern: productData.colorAreas.area2.pattern,
                    fabric: productData.colorAreas.area2.fabric,
                },
                area3: {
                    id: 'area3',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area3,
                    fillPattern: productData.colorAreas.area3.pattern,
                    fabric: productData.colorAreas.area3.fabric,
                },
                area4: {
                    id: 'area4',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area4,
                    fillPattern: productData.colorAreas.area4.pattern,
                    fabric: productData.colorAreas.area4.fabric,
                },
                area5: FOOTBALL && {
                    id: 'area5',
                    parent: 'fabricColors',
                    template: 'menu/multicolors.html',
                    colors: productData.colorAreas.area5,
                    fillPattern: productData.colorAreas.area5.pattern,
                    fabric: productData.colorAreas.area5.fabric,
                },

                OP4: (BASEBALL || SOFTBALL) && {
                    id: 'decorationOption4',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.OP4,
                    fillPattern: productData.options.OP4.type,
                },
                OP4_2: SOFTBALL && {
                    id: 'decorationOption4_2',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.OP4_2,
                    fillPattern: productData.options.OP4_2.type,
                },
                OP4B: BASEBALL && {
                    id: 'decorationOption4B',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.OP4B,
                    fillPattern: productData.options.OP4B.type,
                },
                OP4C: BASEBALL && {
                    id: 'decorationOption4C',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.OP4C,
                    fillPattern: productData.options.OP4C.type,
                },
                OP15: (BASEBALL || SOFTBALL) && {
                    id: 'decorationOption15',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.OP15,
                    fillPattern: productData.options.OP15.type,
                },
                RIBWST: (BASEBALL || SOFTBALL) && {
                    id: 'ribwst',
                    parent: 'options',
                    template: 'menu/multicolors.html',
                    colors: productData.options.RIBWST,
                    fillPattern: productData.options.RIBWST.type,
                },
                beltColor: FOOTBALL && {
                    id: 'beltColor',
                    parent: 'options',
                    template: 'submenu/colors.html',
                    colors: productData.options.beltColor,
                },
                logoColor: {
                    id: 'logoColor',
                    parent: 'options',
                    template: 'submenu/colors.html',
                    colors: productData.options.logoColor,
                },

                type: {
                    id: 'type',
                    parent: 'decorate',
                    template: 'submenu/list.html',
                    list: productData.decoration.type,
                },
                teamName: (SOFTBALL || FOOTBALL) && {
                    id: 'teamName',
                    parent: 'decorate',
                    template: 'menu/lettering.html',
                    text: productData.decoration.teamName.text,
                    decoData: productData.decoration.teamName,
                },
                stockLogo: (SOFTBALL || FOOTBALL) && {
                    id: 'stockLogo',
                    parent: 'decorate',
                    template: 'menu/logo.html',
                    design: productData.decoration.logo.stock.design,
                    size: productData.decoration.logo.stock.size,
                    placement: productData.decoration.logo.stock.placement,
                    colors: productData.decoration.logo.stock.fill,
                },
                customLogo: (SOFTBALL || FOOTBALL) && {
                    id: 'customLogo',
                    parent: 'decorate',
                    template: 'menu/logo.html',
                    customFile: productData.decoration.logo.custom.customFile,
                    size: productData.decoration.logo.custom.size,
                    placement: productData.decoration.logo.custom.placement,
                },
                stockUpperLogo: FOOTBALL && {
                    id: 'stockUpperLogo',
                    parent: 'decorate',
                    template: 'menu/logo.html',
                    design: productData.decoration.upperLogo.stock.design,
                    size: productData.decoration.upperLogo.stock.size,
                    placement: productData.decoration.upperLogo.stock.placement,
                    colors: productData.decoration.upperLogo.stock.fill,
                },
                customUpperLogo: FOOTBALL && {
                    id: 'customUpperLogo',
                    parent: 'decorate',
                    template: 'menu/logo.html',
                    customFile:
                        productData.decoration.upperLogo.custom.customFile,
                    size: productData.decoration.upperLogo.custom.size,
                    placement:
                        productData.decoration.upperLogo.custom.placement,
                },
            }
        }

        var menuDataMap = _.defaults(getBase(designData), {
            jersey: getJersey(designData.jersey),
            jerseyInside:
                FOOTBALL &&
                getJersey(
                    _.defaults(
                        { colorAreas: designData.jersey.colorAreas },
                        designData.jerseyInside
                    )
                ),
            pant: getPant(designData.pant),
        })

        return menuDataMap
    })
