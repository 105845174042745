'use strict'

angular
    .module('mainApp')
    .factory('decorationButtonMap', function(_, designData) {
        function getDecoButton(decoData, code, parentCode) {
            if (decoData.placement && decoData.placement.isDisabled()) {
                return
            }
            if (parentCode) {
                code += ' ' + parentCode
            }
            return {
                code: _.camelCase(code),
                name: _.startCase(code),
                placementProp: decoData.placement,
            }
        }

        function getDecoButtons(decos, code) {
            return _.map(decos, function(deco, childCode) {
                return getDecoButton(deco, childCode, _.capitalize(code))
            })
        }

        return function decorationButtonMap(template) {
            var productData = designData[template.clothingType]

            return _.chain(productData.decoration)
                .map(function(prop, code) {
                    if (code === 'type') {
                        return {
                            code: code,
                            name: 'Decoration Type',
                        }
                    }

                    if (prop.placement) {
                        return getDecoButton(prop, code)
                    } else {
                        return getDecoButtons(prop, code)
                    }
                })
                .flatten()
                .filter()
                .value()
        }
    })
