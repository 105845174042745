'use strict'

angular.module('mainApp').factory('optionsButtonMap', function (_, designData) {
    var sheets = require('data').sheets
    var decoOptions = sheets.decoOptions

    return function optionsButtonMap(template) {
        var productData = designData[template.clothingType]
        return _.chain(productData.options)
            .keys()
            .filter(function(code) {
                return _.contains(template.optionList, code)
            })
            .map(function(code) {
                return {
                    'code': code,
                    'name': decoOptions[code].name,
                }
            })
            .push(designData.filter.clothingFitType && !designData.filter.clothingFitType.isDisabled() && {
                'code': 'clothingFitType',
                'name': 'Fit type',
            })
            .push(designData.filter.clothingCutType && !designData.filter.clothingCutType.isDisabled() && {
                'code': 'clothingCutType',
                'name': 'Cut type',
            })
            .compact()
            .value()

    }
})
