angular.module('mainApp').factory('getCanvasLayers', function() {
    var renderCacheTimestamp = '__RENDER_CACHE_TIMESTAMP__'

    return function (designDataOutput, size, activeView) {
        var query = {
            category: designDataOutput.clothingType,
            view: activeView,
            size: size,
            useShadows: false,
            code: designDataOutput.designTemplate,
            design: designDataOutput[designDataOutput.clothingType],
        }

        return [{
            src: '3d?q=' + encodeURIComponent(JSON.stringify(query)) + '&' + renderCacheTimestamp,
            // crossOrigin: true,
        }]
    }
})
