'use strict'

angular.module('mainApp').factory('filterWatcher', function (
    _,
    $timeout,
    $rootScope,
    LimitedProperty,
    designData
) {

    var sheets = require('data').sheets

    var garmentsWhitelists = designData.designTemplate.legalValues()
    var garments = _.pick(sheets.garments, garmentsWhitelists)

    var scope = $rootScope.$new(true)
    scope.designData = designData
    scope.templateGroups = []
    scope.groupedTemplates = {}

    _.chain(garments)
        .omit(undefined)
        .groupBy('category')
        .each(function(items, category) {
            var legalValues = _.chain(items)
                .reject({ hasAssets: false })
                .pluck('code')
                .value()

            var templateGroup = {
                categoryName: getCategoryName(items[0].category),
                templatesList: new LimitedProperty(legalValues, garments, true),
                allLegalValues: legalValues,
                show: true,
            }
            scope.templateGroups.push(templateGroup)

            scope.groupedTemplates[category] = templateGroup
            scope.$watch('groupedTemplates.' + category + '.templatesList.value()', function(newValue){
                designData.designTemplate.value(newValue, true)
            })
        })
        .value()

    scope.$watch('designData.filter.clothingType.value()', onClothingTypeChange)
    scope.$watch('designData.filter.clothingQuality.value()', onClothingTypeChange)
    scope.$watch('designData.filter.clothingCollection.value()', onClothingCollectionChange)
    scope.$watch('designData.designTemplate.value()', onDesignTemplateChange)

    scope.itemsByClothingType = []
    scope.itemsByCategory = []

    scope.$watchCollection('itemsByClothingType', onItemsByClothingTypeChange)
    scope.$watchCollection('itemsByCategory', onItemsByCategoryChange)

    function getCategoryName(code) {
        return sheets.clothingCollections[code].name
    }

    function onItemsByClothingTypeChange() {
        updateCategoriesCodes()
        updateTemplateCodes()
    }

    function onItemsByCategoryChange() {
        updateTemplateCodes()
    }

    function onClothingTypeChange(clothingType) {
        var clothingType = designData.filter.clothingType && designData.filter.clothingType.value()
        var clothingQuality = designData.filter.clothingQuality && designData.filter.clothingQuality.value()

        var clothingTypePredicate = { clothingType: clothingType }
        var clothingQualityPredicate = { quality: clothingQuality }

        if (!clothingType || clothingType == 'none') {
          clothingTypePredicate = { }
        }
        if (!clothingQuality || clothingQuality == 'none') {
          clothingQualityPredicate = { }
        }        

        scope.itemsByClothingType = _.chain(garments)
            .filter(clothingTypePredicate)
            .filter(clothingQualityPredicate)
            .pluck('code')
            .value()
    }

    function onClothingCollectionChange(category) {

        // CATEGORY

        if (_.isUndefined(category) || _.isEqual(category, 'none')) {
            scope.itemsByCategory = _.clone(garmentsWhitelists)
        }
        else {
            scope.itemsByCategory = _.chain(garments)
                .filter({ category: category })
                .pluck('code')
                .value()
        }
    }

    function onDesignTemplateChange(designTemplate) {
        _.each(scope.groupedTemplates, function(group) {
            group.templatesList.value(_.contains(group.allLegalValues, designTemplate) ? designTemplate : 'none')
        })
    }

    function updateCategoriesCodes() {
        var legalValues = _.chain(garments)
            .pick(scope.itemsByClothingType)
            .groupBy('category')
            .keys()
            .value()

        designData.filter.clothingCollection.legalValues(legalValues)
        if(designData.designTemplate.item() == null) {
          return
        }

        if (!_.contains(legalValues, designData.designTemplate.item().category)) {
            designData.filter.clothingCollection.value('none')
        }
    }

    function updateTemplateCodes() {
        var legalValues = _.intersection(scope.itemsByClothingType, scope.itemsByCategory)
        designData.designTemplate.legalValues(legalValues)

        updateGroupLegalValues()
    }

    // group templates for selection
    function updateGroupLegalValues() {
        var legalValues = designData.designTemplate.legalValues()
        _.each(scope.groupedTemplates, function(group) {
            group.templatesList.legalValues(_.intersection(group.allLegalValues, legalValues))
            group.show = group.templatesList.legalValues().length > 0
        })
    }

    return scope
})
