'use strict'

angular.module('mainApp').factory('productData', function(
    $rootScope,
    _
){
    var productDataContainer = {}

    $rootScope.$watch('designData.clothingType.value()', function(clothingType){
        var productDataSource = $rootScope.designData[clothingType]
        _.forOwn(productDataContainer, function(value, key){
            delete productDataContainer[key]
        })
        _.assign(productDataContainer, productDataSource)
    })

    return productDataContainer
})
