'use strict';

angular.module('mainApp').factory('shadowBlendingMethod', function () {

    var toneProcessor = {
        shadow: {
            min: -100 ,
            max: 190,
            strength: 1
        },
        highlight: {
            min: 130,
            max: 360,
            strength: 0.5
        },
    };

    function mapValue(value, min, max, minResult, maxResult) {
        return minResult + (value - min) / (max - min) * (maxResult - minResult);
    }

    function mapPixelValue(value, min, max, minResult, maxResult) {
        var result = mapValue(value, min, max, minResult, maxResult);
        return Math.max(0, Math.min(Math.round(result), 255));
    }

    return function (canvas, layer) {
        var canvasImageData = canvas.data,
            layerImageData = layer.data,
            i,l;

        for (i = 0, l = canvasImageData.length; i < l; i += 4) {
            if (canvasImageData[i + 3] === 0) {
                continue;
            }

            //shadow multiply
            var shadow = layerImageData[i+3];
            if(shadow < 255) {
                canvasImageData[i]          = canvasImageData[i] * shadow / 255;
                canvasImageData[i + 1]      = canvasImageData[i + 1] * shadow / 255;
                canvasImageData[i + 2]      = canvasImageData[i + 2] * shadow / 255;
            }

            //highlight screen
            var highlight = layerImageData[i];
            if(highlight > 0) {
                canvasImageData[i]          = 255 - (255 - canvasImageData[i]) * (255 - highlight) / 255;
                canvasImageData[i + 1]      = 255 - (255 - canvasImageData[i + 1]) * (255 - highlight) / 255;
                canvasImageData[i + 2]      = 255 - (255 - canvasImageData[i + 2]) * (255 - highlight) / 255;
            }
        }

        return canvas;
    }
});
