'use strict'

angular
    .module('mainApp')
    .factory('getModifiedDesignDataForOrder', function(_, designDataUtils) {
        var data = require('data')
        function mapSolidColor(fillArea, colorProp, applyDesign) {
            return {
                pattern: applyDesign ? fillArea.pattern : 'solidColor',
                color_1: fillArea[colorProp],
                color_2: 'none',
                color_3: 'none',
            }
        }

        return function(designData) {
            var orderData = designData
            var garment = data.findGarment(designData.designTemplate, designData[designData.clothingType])
            var isReversible = garment.isReversible

            if (isReversible) {
                var colorAreas = orderData.jersey.colorAreas

                orderData.jersey.colorAreas = {
                    area1: mapSolidColor(colorAreas.area1, 'color_2', true),
                    area2: mapSolidColor(colorAreas.area1, 'color_1'),
                    area3: mapSolidColor(colorAreas.area2, 'color_2', true),
                    area4: mapSolidColor(colorAreas.area2, 'color_1'),
                    area5: colorAreas.area3,
                }

            }

            return orderData
        }
    })
