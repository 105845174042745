'use strict'

angular
    .module('mainApp')
    .factory('selectors', function(_, $rootScope, designData) {
        var watcherScope = $rootScope.$new(true)
        watcherScope.designData = designData
        watcherScope.calc = {}

        function watch(watchExpression, listener) {
            watcherScope.$watch(watchExpression, listener)
        }

        function watchGroup(watchExpressions, listener) {
            var resultMap = {}
            _.each(watchExpressions, function(expression) {
                watcherScope.$watch(expression, function(result) {
                    resultMap[expression] = result
                    maybeDispatch()
                })
            })
            function maybeDispatch() {
                var hasAllValues = _.every(watchExpressions, function(
                    expression
                ) {
                    return _.has(resultMap, expression)
                })

                if (!hasAllValues) {
                    return
                }
                var resultArray = _.map(watchExpressions, function(expression) {
                    return resultMap[expression]
                })
                try {
                    listener.apply(null, resultArray)
                } catch (error) {
                    console.warn('watchGroup error:', error)
                }
            }
        }

        return {
            watcherScope: watcherScope,
            watch: watch,
            watchGroup: watchGroup,
        }
    })

/* eslint-disable */
angular.module('mainApp').run(function(selectors) {
    // force to activate selectors
})
