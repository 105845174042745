'use strict';

angular.module('mainApp').directive('navigation', function (_, $rootScope, designData, getTemplate, menuDataMap, colorAreaButtonMap, optionsButtonMap, selectButtonMap, decorationButtonMap) {
    var tabs = require('data').sheets.tabs
    var tabsWhitelist = require('data').whitelists.tabs.all

    var tabList = {
        itemMap: tabs,
        allCodes: tabsWhitelist,
    }

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'navigation.html',
        controller: function($scope) {

            var ROOT_MENU = 'rootMenu';

            // cache
            var openedMenus = angular.copy(tabs),
                animationEnabled = false,
                doAnimation = false;

            $scope.designData = designData;
            $scope.tabList = tabList;
            $scope.activeTab = _.first(tabsWhitelist);
            $scope.menuData = menuDataMap[$scope.activeTab];
            $scope.menuTemplate = $scope.menuData.template;

            $scope.$watch('designData.designTemplate.value()', function (templateID) {
                var template = designData.designTemplate.item()

                $scope.colorAreaButtons = colorAreaButtonMap[templateID]
                $scope.optionsButtonMap = optionsButtonMap(template)
                $scope.selectButtonMap = selectButtonMap

                setDecorationButtonMap()
            })
            $rootScope.$watch('decoDependenciesUpdateCounter', setDecorationButtonMap)

            function setDecorationButtonMap() {
                var template = designData.designTemplate.item()
                if (template == null) {
                  return
                }
                var decoSet = designData[template.clothingType].decoration.type.value()

                $scope.decorationButtonMap = decorationButtonMap(template, decoSet)
            }

            $scope.$watch('menuName', changeMenu);

            $scope.openTab = function(tabCode) {
                var openedMenu = openedMenus[tabCode].openedMenu,
                    codes = tabsWhitelist;

                animationEnabled = false;
                doAnimation = false;

                $scope.tabDirection = (codes.indexOf($scope.activeTab) > codes.indexOf(tabCode)) ? 'backward' : 'forward';

                $scope.$emit('changeMenu', (openedMenu ? openedMenu : tabCode));
            };

            $scope.isMenuActive = isMenuActive;

            $scope.open = function(menuName) {
                return (isMenuActive(menuName) && !doAnimation);
            };

            $scope.expand = function(menuName) {
                return (isMenuActive(menuName) && doAnimation);
            };

            function isMenuActive(menuName) {
                return !!_.find(openedMenus, function (tabData) {
                    return tabData.openedMenu === menuName;
                });
            }

            function changeMenu(menuName, oldMenuName) {
                if (menuName === undefined && oldMenuName === undefined) {
                    return;
                }

                var activeMenu = menuName || ROOT_MENU,
                    activeTab = $scope.activeTab,
                    clothingType = getTemplate().clothingType;

                var submenuData = menuDataMap[activeMenu] || menuDataMap['jersey'][activeMenu] || menuDataMap['pant'][activeMenu] || null;

                if (!activeMenu || !submenuData) {
                    openedMenus[activeTab].openedMenu = '';

                    $scope.$emit('changeMenu', activeTab);
                    return;
                }

                activeTab = $scope.activeTab = submenuData['parent'] || activeMenu;

                if (!menuDataMap[activeMenu] && !menuDataMap[clothingType][activeMenu]) {
                    $scope.$emit('changeMenu', activeTab);
                    return;
                }

                if (animationEnabled) {
                    doAnimation = true;
                }

                animationEnabled = true;

                openedMenus[activeTab].openedMenu = activeMenu;

                $scope.menuData = menuDataMap[activeTab];
                $scope.menuTemplate = $scope.menuData.template;
            }
        }
    }
});
