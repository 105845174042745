'use strict';

angular.module('mainApp').run(function (_, dataModelUpgrader) {

    var upgrade200to201 = require('data/model').upgradeVersion['from2.0.0to2.0.1'];
    var upgrade201to202 = require('data/model').upgradeVersion['from2.0.1to2.0.2'];
    var upgrade202to300 = require('data/model').upgradeVersion['from2.0.2to3.0.0'];
    var upgrade300to301 = require('data/model').upgradeVersion['from3.0.0to3.0.1'];
    var upgrade301to302 = require('data/model').upgradeVersion['from3.0.1to3.0.2'];
    var upgrade302to303 = require('data/model').upgradeVersion['from3.0.2to3.0.3'];
    var upgrade3to4 = require('data/model').upgradeVersion['from3to4'];
    var upgrade400to410 = require('data/model').upgradeVersion['from4.0.0to4.1.0'];
    var upgrade410to420 = require('data/model').upgradeVersion['from4.1.0to4.2.0'];
    var upgrade420to430 = require('data/model').upgradeVersion['from4.2.0to4.3.0'];
    var upgrade430to440 = require('data/model').upgradeVersion['from4.3.0to4.4.0'];
    var upgrade440to450 = require('data/model').upgradeVersion['from4.4.0to4.5.0'];
    var upgrade450to460 = require('data/model').upgradeVersion['from4.5.0to4.6.0'];
    var upgrade460to470 = require('data/model').upgradeVersion['from4.6.0to4.7.0'];
    var upgrade470to480 = require('data/model').upgradeVersion['from4.7.0to4.8.0'];
    var upgrade480to490 = require('data/model').upgradeVersion['from4.8.0to4.9.0'];
    var upgrade490to4100 = require('data/model').upgradeVersion['from4.9.0to4.10.0'];

    dataModelUpgrader.registerUpgrader('2.0.0', function (projectData) {
        projectData = upgrade200to201(projectData);
        projectData = upgrade201to202(projectData);
        projectData = upgrade202to300(projectData);
        projectData = upgrade300to301(projectData);
        projectData = upgrade301to302(projectData);
        projectData = upgrade302to303(projectData);
        projectData = upgrade3to4(projectData);
        projectData = upgrade400to410(projectData);
        projectData = upgrade410to420(projectData);
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('2.0.1', function (projectData) {
        projectData = upgrade201to202(projectData);
        projectData = upgrade202to300(projectData)
        projectData = upgrade300to301(projectData)
        projectData = upgrade301to302(projectData);
        projectData = upgrade302to303(projectData);
        projectData = upgrade3to4(projectData);
        projectData = upgrade400to410(projectData);
        projectData = upgrade410to420(projectData);
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('2.0.2', function (projectData) {
        projectData = upgrade202to300(projectData)
        projectData = upgrade300to301(projectData)
        projectData = upgrade301to302(projectData);
        projectData = upgrade302to303(projectData);
        projectData = upgrade3to4(projectData);
        projectData = upgrade400to410(projectData);
        projectData = upgrade410to420(projectData);
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('3.0.0', function (projectData) {
        projectData = upgrade300to301(projectData)
        projectData = upgrade301to302(projectData);
        projectData = upgrade302to303(projectData);
        projectData = upgrade3to4(projectData);
        projectData = upgrade400to410(projectData);
        projectData = upgrade410to420(projectData);
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('3.0.1', function (projectData) {
        projectData = upgrade301to302(projectData);
        projectData = upgrade302to303(projectData);
        projectData = upgrade3to4(projectData);
        projectData = upgrade400to410(projectData);
        projectData = upgrade410to420(projectData);
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('3.0.2', function (projectData) {
        projectData = upgrade302to303(projectData);
        projectData = upgrade3to4(projectData);
        projectData = upgrade400to410(projectData);
        projectData = upgrade410to420(projectData);
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('3.0.3', function (projectData) {
        projectData = upgrade3to4(projectData);
        projectData = upgrade400to410(projectData);
        projectData = upgrade410to420(projectData);
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.0.0', function (projectData) {
        projectData = upgrade400to410(projectData);
        projectData = upgrade410to420(projectData);
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.1.0', function (projectData) {
        projectData = upgrade410to420(projectData);
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.2.0', function (projectData) {
        projectData = upgrade420to430(projectData);
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.3.0', function (projectData) {
        projectData = upgrade430to440(projectData);
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.4.0', function (projectData) {
        projectData = upgrade440to450(projectData);
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.5.0', function (projectData) {
        projectData = upgrade450to460(projectData);
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);

        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.6.0', function (projectData) {
        projectData = upgrade460to470(projectData);
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);
        
        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.7.0', function (projectData) {
        projectData = upgrade470to480(projectData);
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);
        
        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.8.0', function (projectData) {
        projectData = upgrade480to490(projectData);
        projectData = upgrade490to4100(projectData);
        
        return projectData;
    });

    dataModelUpgrader.registerUpgrader('4.9.0', function (projectData) {
        projectData = upgrade490to4100(projectData);
        
        return projectData;
    });
});
