'use strict';

angular
    .module('mainApp')
    .factory('designDataUtils', function(_, designData, vendorConfig, configService) {
        // NB! configService is needed here to guarantee that vendorConfig is already mutated.
        // Don't want to refactor now.
        var sheets = require('data').sheets;
        var whitelists = require('data').whitelists;

        function getSkuData() {
            var product = designData.designTemplate.item();
            function getDataFromSkuMap() {
                var fitType =
          designData.filter.clothingFitType &&
          designData.filter.clothingFitType.value();
                var cutType =
          designData.filter.clothingCutType &&
          designData.filter.clothingCutType.value();

                return product.skuMap['fitType_' + fitType]
                    ? product.skuMap['fitType_' + fitType]['cutType_' + cutType]
                    : product.skuMap['cutType_' + cutType];
            }
            var skuData = product.skuMap ? getDataFromSkuMap() || product.code : product.code;
            skuData = _.isString(skuData) ? { sku: skuData } : skuData;
            if (vendorConfig.productSubset !== 'bsn') {
                delete skuData.price;
            }
            skuData.note = skuData.note || product.skuNote
            return skuData;
        }
        
        function getSkuLabelData() {
          var data = getSkuData()
          
          var base = [data.sku]
          if(designData.filter.clothingCutType) {
            base.push(designData.filter.clothingCutType.text())
          }
          
          var more = []
          if (data.note) {
              more.push(data.note)
          }
          if (data.price) {
              more.push('$' + data.price)
          }

          return {
            base: base.join(' - '),
            more: more.join(' - '),
          }
        }

        function hasOutlineColor2(decoType, fontCode) {
            var font = sheets.fonts[fontCode];
            return font.outlines === 2;
        }

        function isTailingAvailable(prop) {
            var font = sheets.fonts[prop.font];
            var layout = sheets.layouts[prop.layout];

            return font.canHaveTailing && layout.canHaveTailing;
        }

        function setLegalValuesDeep(data, legalValues, propKey) {
            if (_.isNull(legalValues)) {
                data.legalValues([]);
                return;
            }

            if (_.isArray(legalValues)) {
                if (legalValues.length) data.legalValues(legalValues);
                return;
            }

            if (_.isString(legalValues)) {
                if (propKey === 'pattern')
                    legalValues = whitelists.designPatterns[legalValues];
                else {
                    legalValues = whitelists.colors[legalValues];
                }

                if (legalValues) data.legalValues(legalValues);
                return;
            }

            if (_.isObject(legalValues)) {
                if (_.isBoolean(legalValues.isAvailable)) {
                    data.placement.isDisabled(!legalValues.isAvailable);
                }

                _.each(legalValues, function(propLegalValues, key) {
                    if (key === 'isAvailable') {
                        return;
                    }
                    if (data[key] == null) {
                        console.error('key', key, 'is not found on', data, legalValues);
                    }
                    setLegalValuesDeep(data[key], propLegalValues, key);
                });

                return;
            }
        }

        return {
            getSkuData: getSkuData,
            getSkuLabelData: getSkuLabelData,
            hasOutlineColor2: hasOutlineColor2,
            isTailingAvailable: isTailingAvailable,
            setLegalValuesDeep: setLegalValuesDeep,
        };
    });
