'use strict';

angular.module('mainApp').config(function($provide) {
    $provide.decorator('tileDirective', ['$delegate', function ($delegate) {
        //$delegate is array of all ng-click directive
        //in this case frist one is angular buildin ng-click
        //so we remove it.
        $delegate.shift()
        return $delegate
    }])
})


angular.module('sdk').directive('tile', function (assetConfig) {
    var colors = require('data').sheets.colors
    return {
        restrict: 'E',
        replace: true,
        templateUrl: function(element, attributes) {
            return 'tiles/' + (attributes.tileTemplate || 'standard') + 'Tile.html';
        },
        link: function(scope, element, attributes) {
            var block = element;

            if (!element.hasClass('tiles__item')) {
                block = element.children().eq(0);
            }

            scope.item = scope.optionData.item(scope.itemCode);

            if(!scope.item){
                console.error("Could not find item " + scope.itemCode + " in property " + scope.optionData.$path + '. Legal values are ' + scope.optionData.legalValues())
                return
            }

            // set background color if "hex" value is available for "item"
            if (scope.item.hex) {
                scope.$watch('item.hex', updateBackgroundColor, true);
            }

            // set background image if item has "icon" key with the path to a image file
            if (scope.item.icon && !scope.item.embed) {
                scope.$watch('item.icon', updateBackgroundImage);
            }

            function updateBackgroundColor(hex) {
                if (typeof(scope.item.hex) === 'string') {
                    scope.color = {'background-color': hex, 'color': colors[scope.item.altColor].hex}
                    scope.color2 = {}
                    scope.color3 = {}
                    scope.hover = {'background-color': hex, 'color': colors[scope.item.altColor].hex}
                } else if (Array.isArray(scope.item.hex)) {
                    var altColor = scope.item.altColor
                    if(scope.item.hex.length === 2){
                        altColor = colors[scope.item.code.split('-')[1]].altColor
                    }
                    scope.color = {'background-color': hex[0], 'color': colors[altColor].hex}
                    scope.color2 = {'background-color': hex[1]}
                    scope.color3 = {'background-color': hex[2]}
                    scope.hover = {'background-color': hex[0], 'color': colors[scope.item.altColor].hex}
                }
            }

            function updateBackgroundImage(path) {
                block.css('background-image', 'url(' + path + '?' + assetConfig.minorVersion + ')');
            }
        }
    }
});
