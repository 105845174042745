'use strict'

angular.module('mainApp').factory('colorList', function () {


    var sheets = require('data').sheets
    var whitelists = require('data').whitelists

    return {
        itemMap: sheets.colors,
        allCodes: whitelists.colors.all,
    }
})
