'use strict'

angular
    .module('mainApp')
    .factory('templateWatcher', function(
        _,
        $rootScope,
        designData,
        updateCutAndFitTypes
    ) {
        var sheets = require('data').sheets
        var whitelists = require('data').whitelists
        var areaFillExceptions = sheets.areaFillExceptions

        var scope = $rootScope.$new(true)
        scope.designData = designData

        scope.$watch('designData.designTemplate.value()', onTemplateChanged)

        function onTemplateChanged(templateID) {
            if (!templateID) {
                return
            }

            var template = designData.designTemplate.item()
            var clothingType = template.clothingType

            designData.clothingType.value(clothingType, true)

            designData.isReversed &&
                designData.isReversed.isDisabled(
                    '!template.isReversible',
                    !template.isReversible
                )
            designData.jerseyInside &&
                designData.jerseyInside.isDisabled(
                    '!template.isReversible',
                    !template.isReversible
                )

            if (designData.filter.clothingFitType) {
                designData.filter.clothingFitType.isDisabled(
                    'clothingType === "pant"',
                    clothingType === 'pant'
                )
            }

            updateCutAndFitTypes(template)

            updateAllProductData(template, clothingType)
        }

        function updateAllProductData(template, clothingType) {
            updateProductData(designData[clothingType], template, clothingType)

            if (designData.jerseyInside && clothingType === 'jersey') {
                updateProductData(
                    designData.jerseyInside,
                    template,
                    clothingType
                )
            }
        }

        function updateProductData(productData, template, clothingType) {
            productData.options.buttonColor &&
                productData.options.buttonColor.isDisabled(
                    '!template.options.buttonColor',
                    !template.options.buttonColor
                )

            updateColorAreas(productData.colorAreas, template)
            updateOptionsAvailability(productData, template)
            updateOptionsColors(productData, template)

            updateLogoColors(productData, template)
            if (productData.options.beltColor && clothingType === 'pant') {
                updateBeltColors(productData.options.beltColor, template)
            }
        }

        function updateOptionsAvailability(productData, template) {
            _.each(productData.options, function(prop, option) {
                prop.type &&
                    prop.type.isDisabled(
                        '!_.contains(template.optionList, option)',
                        !_.contains(template.optionList, option)
                    )
            })
        }

        function updateOptionsColors(productData, template) {
            var colorWhitelist =
                template.category === 'sublimatedJersey'
                    ? whitelists.colors.sublimated
                    : whitelists.colors.braid
            _.each(productData.options, function(prop, option) {
                if (_.startsWith(option, 'OP')) {
                    prop.color_1.legalValues(colorWhitelist)
                    prop.color_2.legalValues(colorWhitelist)
                    prop.color_3.legalValues(colorWhitelist)
                }
            })
        }

        function updateColorAreas(colorAreas, template) {
            var exceptions = _.indexBy(
                areaFillExceptions[template.code],
                'area'
            )
            _.each(colorAreas, function(colorArea, areaId) {
                var isDisabled = template.colorAreas.titles[areaId] == null
                colorArea.isDisabled(
                    'template.colorAreas.titles[areaId] == null',
                    isDisabled
                )
                var patternSetId = template.colorAreas.patternSets[areaId]
                var patterns = whitelists.designPatterns[patternSetId]
                var colorSetId = template.colorAreas.colorSets[0]

                var exception = exceptions && exceptions[areaId]

                if (exception) {
                    if (exception.patterns && exception.patterns.length > 0) {
                        patterns = exception.patterns
                    }
                }
                colorArea.pattern.legalValues(patterns)

                if (colorArea.fabric) {
                    colorArea.fabric.legalValues(template.colorAreas.colorSets)
                    colorSetId = colorArea.fabric.value()
                }
                var colorSetIdException = null
                if (exception) {
                    if (exception.colorSet) {
                        colorSetIdException = exception.colorSet
                    }
                }
                var colors =
                    whitelists.colors[colorSetIdException || colorSetId]
                if (_.isUndefined(colors)) {
                    console.warn(
                        'Color set',
                        colorSetId,
                        'nor colorSetIdException',
                        colorSetIdException,
                        'for',
                        areaId,
                        'not found in',
                        whitelists.colors
                    )
                }
                _.each(colorArea, function(colorProp, key) {
                    if (!_.startsWith(key, 'color_')) {
                        return
                    }
                    colorProp.legalValues(colors)
                    colorProp._exception = colorSetIdException
                })
            })
        }

        function updateLogoColors(productData, template) {
            var colorCodes = whitelists.colors[template.options.logoColor]
            productData.options.logoColor.isDisabled('!colorCodes', !colorCodes)
            colorCodes && productData.options.logoColor.legalValues(colorCodes)
        }

        function updateBeltColors(beltColorProp, template) {
            var colorCodes = whitelists.colors[template.options.beltColor]
            beltColorProp.isDisabled('!colorCodes', !colorCodes)
            colorCodes && beltColorProp.legalValues(colorCodes)
        }
    })

/* eslint-disable */
angular.module('mainApp').run(function(templateWatcher) {
    // force to activate templateWatcher
})
